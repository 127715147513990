@font-face {
  font-display: swap;
  font-family: "Pangram Pangram";
  src: url("/assets/fonts/PPNeueMontreal-Book.woff2") format("truetype");
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: "Pangram Pangram";
  src: url("/assets/fonts/PPNeueMontreal-Medium.woff2") format("truetype");
  font-weight: 500;
}

:root {
  
  --sm: 640px;
  --lg: 1024px;
  --xl: 1280px;
  --xl2: 1536px;

  --text-size: 20px;

  /* menu */
  --menu-title: 15px;
  --menu-list: 14px;
  --section-padding-top: 420px;

  /* top */

  /* about */

  --about-text-ja-weight: 501;

  /* services */
  --design-title-size: 80px;
  --services-text-main-size: 40px;
  --services-text-ja-main-size: 40px;

  --services-text-main-sub: 20px;
  --services-text-ja-main-sub: 20px;
  --services-text-ja-main-weight: 501;

  --services-image-margin-top: -65px;

  /* system */
  --system-title: 100px;
  --system-title-info: 40px;
  --top-title: -120px;
  --system-text-width: 586px;
  --system-info-title-height: 196px;
  --system-info-text: 20px;
  
  /* showcase */
  --showcase-title: 120px;
  --showcase-title-sub: 80px;
  --showcase-text-width: 485px;

  /* vision */
  --vision-text: 80px;
  --vision-text-ja: 80px;
  --vision-text-ja-weight: 501;
  
  /* teams */
  --title-teams: 120px;
  
  --teams-text-width: 384px;
  --teams-text-size: 16px;
  --teams-text-ja-size: 19.7px;
  --teams-logo-size: 40px;
  --teams-padding-bottom: 800px;

  /* contact */
  --contact-title: 140px;
  --contact-title-sub: 120px;
  --contact-text-size: 14px;
  --contact-footer-text-size: 14px;
  
  /* icons */
  --icon-back-scale: 1;
  --menu-mobile-text: 14px;  

}

@media screen and (min-width: 1537px) {

  :root {
  
    /* common */
    --section-width: 1536px;
    --section-padding: 168px;

    /* menu */
    --menu-width: 100vw;
    --menu-padding: 46px;

    /* top */
    --top-title-size: 120px;
    --top-title-left: 290px;
    --top-text-left: 1022px;
    --top-text-space: 1em;

    /* about */
    --about-text-left: 488px;
    --about-text-size: 60px;

    /* services */
    --services-environments-text-padding: 610px;
    --services-interactive-text-padding: 366px;
    --services-remote-text-padding: 488px;
    --services-image-width: 944px;
    --services-image-height: 648px;

    /* system */
    --system-image-width: 1126px;
    --system-image-height: 774px;

    /* showcase */
    /* vision */
    /* teams */

    /* contact */
    --contact-text-left: 244px;
    --contact-title-upper: 140px;
    --contact-title-lower: 125px;
    --contact-footer-left: 244px;

    /* footer */

    /* icons */
    --icon-back-scale: 1.333;

  }
    
}

@media screen and (max-width: 1536px) {

  :root {
    /* common */
    --section-width: 1280px;
    --section-padding: 145px;

    /* mennu */
    --menu-width: 100vw;
    --menu-padding: 44px;

    /* top */
    --top-title-size: 100px;
    --top-title-left: 246px;
    --top-text-left: 852px;
    --top-text-space: 1em;

    /* about */
    --about-text-left: 303px;
    --about-text-size: 60px;

    /* services */
    --services-environments-text-padding: 404px;
    --services-interactive-text-padding: 202px;
    --services-remote-text-padding: 303px;

    --services-image-width: 788px;
    --services-image-height: 598px;

    /* system */
    --system-image-width: 944px;
    --system-image-height: 648px;

    /* contact */
    --contact-text-left: 202px;
    --contact-title-upper: 140px;
    --contact-title-lower: 125px;
    --contact-footer-left: 202px;
  }
  
}

@media screen and (max-width: 1280px) {

  :root {
    /* common */
    --section-width: 1024px;
    --section-padding: 52px;

    /* menu */
    --menu-width: 1024px;
    --menu-padding: 52px;

    /* title */
    --top-title-size: 110px;
    --top-title-left: 52px;
    --top-text-left: 52px;
    --top-text-space: 0.5em;

    /* about */
    --about-text-left: 156px;
    --about-text-size: 60px;

    /* services */
    --services-environments-text-padding: 0;
    --services-interactive-text-padding:  0;
    --services-remote-text-padding: 0;
    --services-image-width: 686px;
    --services-image-height: 521px;
    --services-image-margin-top: -55px;

    /* system */
    --system-image-width: 794px;
    --system-image-height: 524px;

    --system-info-image-width: 230px;
    --system-info-image-height: 147px;

    /* showcase */
    --showcase-text-width: 606px;

    /* contact */
    --contact-text-left: 156px;
    --contact-title-upper: 144px;
    --contact-title-lower: 133px;
    --contact-footer-left: 156px;
    --contact-text-size: 17px;
    --contact-footer-text-size: 17px;

  }
 
  @media screen and (max-width: 1023px) {

    :root {
      /* common */
      --section-width: 100%;
      --section-padding: 35px;
      --section-padding-top: 320px;

      /* menu */
      --menu-width: 100%;
      --menu-padding: 25px;
      
      /* top */
      --top-title-size: 13vw;
      --top-title-left: 35px;
      --top-text-left: 35px;
      --top-text-space: 0.5em;
      
      /* about */
      --about-text-left: 5vw;
      --about-text-size: 6vw;

      /* services */
      --design-title-size: 10vw;
      --services-environments-text-padding: 0;
      --services-interactive-text-padding:  0;
      --services-remote-text-padding: 0;
      --services-image-width: 100%;
      --services-image-height: calc(100vw * 0.758582524271845 * 0.85);
      --services-image-margin-top: -45px;

      /* system */
      --system-title: 12vw;
      --top-title: -60px;
      --system-text-width: unset;
      --system-title-info: 4.5vw;
      --system-image-width: 100%;
      --system-image-height: calc(100vw * 0.686440677966102 * 0.95);
      --system-info-image-width: 303px;
      --system-info-image-height: 193px;
  
      /* showcase */
      --showcase-title-sub: 8vw;
      --showcase-title: 12vw;
      --showcase-text-width: unset;

      /* vision */
      --vision-text: 9vw;
      --vision-text-ja: 8.2vw;
      --vision-text-height: 1.25em;

      /* teams */
      --title-teams: 12vw;
      --teams-text-width: unset;
      --teams-padding-bottom: 400px;

      /* contact */
      --contact-title: 14.5vw;
      --contact-title-sub: 14.5vw;

      --contact-text-left: 13vw;
      --contact-title-upper: 144px;
      --contact-title-lower: 133px;
      --contact-footer-left: 156px;
    }

  }

  @media screen and (max-width: 640px) {

    :root{
      --text-size: 17px;
      --section-padding: 25px;

      --top-title-left: 25px;
      --top-text-left: 25px;

      --about-text-size: 7.5vw;

      --services-text-main-size: 6vw;
      --services-text-ja-main-size: 5.8vw;
      --services-text-main-sub: 17px;
      --services-text-ja-main-sub: 17px;
      --services-image-margin-top: -35px;

      --system-info-text: 17px;
      --system-info-title-height: 130px;

      --teams-text-ja-size: 17px;
      --teams-logo-size: 30px;

      --contact-text-size: 14px;
      --contact-footer-text-size: 14px;
    }

  }

  @media screen and (max-width: 1023px) {

    @media screen and (orientation:landscape) {
     
      :root{
        --top-title-size: 13vh;
        --contact-title: 14.5vh;
        --contact-title-sub: 14.5vh;
      }

    }

  }

}

html {
  /*overflow: hidden;*/
}

body {
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Pangram Pangram", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 500;
  min-width: 320px;
  background-color: #000;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#main{
  height: 100%;
  position: fixed;
  /*overflow-y: scroll;*/
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
ul,
li {
  line-height: 1em;
}

h2,
h3 {
  line-height: 1.125em;
}

h1,
h2,
h3 {
  text-transform: uppercase;
  pointer-events: none;
}

p {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5 {
  user-select: none;
}
